// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
$custom-typography: mat-typography-config(
  $font-family: 'Montserrat, monospace',
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$who-am-i-primary: mat-palette($mat-blue-grey);
$who-am-i-accent: mat-palette($mat-orange);

:root {
  --zorbees-kitchen-primary-color: #6DA82C;
  --primary-color: #B9C8CF;
  --secondary-color: #607d8b;
  --accent-color: #ff9800;
  --text-background-color: #F3F6F7;
}

// The warn palette is optional (defaults to red).
$who-am-i-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$who-am-i-theme: mat-light-theme((
  color: (
    primary: $who-am-i-primary,
    accent: $who-am-i-accent,
    warn: $who-am-i-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($who-am-i-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, Roboto, sans-serif;
}

.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.heading-bar {
  background-color: #608A7C;
  color: white;
}
